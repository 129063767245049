import React from "react";
import { IconWithCallout } from "src/components/atoms/IconWithCallout";
import {QuestionFormToggle} from "./QuestionFormToggle";

export interface Props {
	checked: boolean;
	disabled: boolean;
	visible: boolean;
	onChanged: (checked:boolean)=>void;
}

export const EnableVotesSplittingToggle = (props: Props) => {
	return (
		<>
			{props.visible && (
				<>
					<QuestionFormToggle
						checkedLabelText="Stimmensplitting"
						uncheckedLabelText="Stimmensplitting"
						checked={props.checked}
						disabled={props.disabled}
						visible={props.visible}
						onChanged={props.onChanged}
						onRenderSuffix={(()=>
							<IconWithCallout
								iconName='Info'
								renderCalloutContent={()=>{ return (
									<span>Mit aktiviertem Stimmensplitting sind die Teilnehmer dazu in der Lage, ihr Stimmengewicht auf die unterschiedlichen Antwortmöglichkeiten zu verteilen.</span>
								);}}
								iconContainerClassName="votrInfoIcon"
							/>
						)}
					/>
				</>
			)}
		</>
	);
};