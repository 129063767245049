import styles from './Sidepanel.module.scss'; // TODO: use own module.scss
import React, { useState, useEffect } from 'react';
import { IQuestion, IAnswerOption } from '../../store/business/interfaces';
import { PrimaryButton, DefaultButton, CommandButton, Icon, ChoiceGroup } from 'office-ui-fabric-react';
import { createGuid } from '../../shared/utils';
import { AutomaticMultilineTextField } from '../atoms/AutomaticMultilineTextField';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { RTEField } from './RTEField';
import { SecurityToggle } from './QuestionForm/SecurityToggle';
import { MultipleAnswerOptionsToggle } from './QuestionForm/MultipleAnswerOptionsToggle';
import { LimitAnswerOptionsToggle } from './QuestionForm/LimitAnswerOptionsToggle';
import { EnableVotesSplittingToggle } from './QuestionForm/EnableVotesSplittingToggle';
import { AnswerOptionsList } from './QuestionForm/AnswerOptionsList';
import { QuestionService } from '../../services/QuestionService';

export interface Props {
	question: IQuestion;
	disabled: boolean;
	onSave: (question: IQuestion) => void;
	onCancel: () => void;
}

export const QuestionForm = (props: Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const members = useSelector((state: AppState) => state.business.members);
	const [title, setTitle] = useState(props.question.title);
	const [description, setDescription] = useState(props.question.description);
	const [kind, setKind] = useState(props.question.kind);
	const [security, setSecurity] = useState(props.question.security);
	const [limitVotesPerMember, setLimitVotesPerMember] = useState(
		props.question.votesPerMember !== 1 && props.question.votesPerMember < props.question.answerOptions.length
	);
	const [votesPerMember, setVotesPerMember] = useState(props.question.votesPerMember);
	const [allowVotesSplitting, setAllowVotesSplitting] = useState(props.question.allowVotesSplitting);
	const [answerOptions, setAnswerOptions] = useState<IAnswerOption[]>(() => {
		if (props.question.answerOptions.length === 0) {
			return [
				{ id: createGuid(), title: 'Antwort 1', attendees: [], votesCount: 0, type: 'answer' },
				{ id: createGuid(), title: 'Antwort 2', attendees: [], votesCount: 0, type: 'answer' },
			];
		}
		return props.question.answerOptions;
	});

	const enableVotesSplitting = !!members.entities.find((m) => m.voteWeight > 1) && !survey.useVoteWeightInPercent;

	const [titleIsValid, setTitleIsValid] = useState(true);
	const [showValidationMessages, setShowValidationMessages] = useState(false);

	useEffect(() => {
		setVotesPerMember((value) => {
			if (value !== 1 && value > answerOptions.length && answerOptions.length > 0) {
				value = answerOptions.length;
			}
			return value;
		});
	}, [answerOptions]);

	useEffect(() => {
		setTitleIsValid(title > '');
	}, [title]);

	const onClickSave = () => {
		setShowValidationMessages(true);

		if (titleIsValid && (kind !== 'text' || answerOptions.length > 0)) {
			const question = {
				...QuestionService.emptyQuestion(),
				id: props.question.id,
				title,
				description,
				kind,
				security,
				attendees: [],
				status: props.question.status,
				eTag: props.question.eTag,
				loaded: undefined
			};
			if(kind === 'choice') {
				question.votesPerMember = (votesPerMember !== 1) ? (limitVotesPerMember ? votesPerMember : answerOptions.length) : 1; // TODO: make clearer, nobody can read this
				question.allowVotesSplitting = allowVotesSplitting;
				question.answerOptions = answerOptions;
			}
			else if(kind === 'text') {
				question.votesPerMember = 1;
				question.allowVotesSplitting = false;
				question.answerOptions = [];
			}
			props.onSave(question);
		}
	};

	return (
		<div className={styles.formContainer}>
			<header>
				<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
					<div className={styles.panelHeader}>
						<h1>Abstimmung {props.question.status === 'unsaved' ? 'anlegen' : 'bearbeiten'}</h1>
					</div>
					<div className={styles.panelButtons}>
						<CommandButton
							onClick={() => {
								props.onCancel();
							}}
							iconProps={{ iconName: 'ChromeClose' }}
							className={styles.closeIcon}
						/>
					</div>
				</div>
			</header>
			<div className={styles.surveyTitleInput}>
				<AutomaticMultilineTextField
					title='Frage der Abstimmung'
					defaultValue={props.question.title}
					onChange={(evt, val) => {
						setTitle(val);
					}}
					onBlur={() => setShowValidationMessages(true)}
					disabled={props.disabled}
					placeholder='Frage der Abstimmung eingeben'
					onFocus={(evt) => {
						evt.currentTarget.select();
					}}
					errorMessage={!titleIsValid && showValidationMessages ? 'Bitte geben Sie eine Frage ein.' : ''}
					maxLength={300}
				/>
			</div>
			<RTEField
				text={props.question.description}
				placeholder={'Beschreibung der Abstimmung eingeben (optional)'}
				onBlur={(newvalue) => setDescription(newvalue)}
				disabled={props.disabled}
				maxLength={30000} /* max size of a cell in table storage entity is 32.768, leave some space there */
			/>

			<ChoiceGroup
				label={'Antworttyp'}
				defaultSelectedKey={kind}
				options={[
					{ key: 'choice', text: 'Auswahl', iconProps: { iconName: 'CheckList' } },
					{ key: 'text', text: 'Texteingabe', iconProps: { iconName: 'InsertTextBox' } },
				]}
				onChange={(ev, option) => {
					setKind(option.key as 'choice' | 'text');
				}}
				className={styles.questionKindSelector}
			/>

			{kind === 'choice' && (
				<>
					<SecurityToggle
						checked={security === 'private'}
						disabled={props.disabled}
						onChanged={(checked) => setSecurity(checked ? 'private' : 'public')}
					/>
					<MultipleAnswerOptionsToggle
						checked={votesPerMember !== 1}
						disabled={props.disabled}
						visible={!survey.useVoteWeightInPercent}
						onChanged={(checked) => setVotesPerMember(checked ? 2 : 1)}
					/>
					<LimitAnswerOptionsToggle
						checked={limitVotesPerMember}
						disabled={props.disabled}
						visible={votesPerMember !== 1}
						maxAnswerOptionCount={answerOptions.length}
						answerOptionCount={votesPerMember}
						onToggleChanged={setLimitVotesPerMember}
						onMaxAnswerOptionCountChange={setVotesPerMember}
					/>
					<EnableVotesSplittingToggle
						checked={allowVotesSplitting}
						disabled={props.disabled}
						visible={enableVotesSplitting}
						onChanged={setAllowVotesSplitting}
					/>
					<AnswerOptionsList
						answerOptions={answerOptions}
						disabled={props.disabled}
						onChange={setAnswerOptions}
					/>
				</>
			)}
			{kind === 'text' && (
				<>
					<div className={styles.infoBox}>
						<Icon iconName={'Info'} /><span>Teilnehmer können eine freie Antwort eingeben. Dieser Antworttyp eignet sich besonders gut, um ein Stimmungsbild von den Teilnehmern abzufragen.</span>
					</div>
					<SecurityToggle
						checked={security === 'private'}
						disabled={props.disabled}
						onChanged={(checked) => setSecurity(checked ? 'private' : 'public')}
					/>
				</>
			)}

			<div className={styles.buttonContainer}>
				<DefaultButton text='Abbrechen' onClick={() => props.onCancel()} />
				<PrimaryButton text='Speichern' onClick={onClickSave} disabled={props.disabled} />
			</div>
		</div>
	);
};
