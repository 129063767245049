import * as React from 'react';
import styles from './App.module.scss';
import { store, AppState } from './store';
import { Provider, useSelector } from 'react-redux';
import { loadTheme, ProgressIndicator, Dialog, DialogType, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { config } from './store/system/actions';
import { SystemService } from './services/SystemService';
import { Hub } from './components/routes/Hub';
import { LoadingOverlay } from './components/atoms/LoadingOverlay';
import { Messages } from './shared/components/atoms/Messages';
import { SignalR } from './shared/SignalRContext';
import { HashRouter, useHistory, useLocation } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BusinessDataType } from './store/business/enums';
import { xhrEvents } from './xhr';
import { useEffect, useState } from 'react';
import { SurveyService } from './services/SurveyService';
import { MemberService } from './services/MemberService';
import { isEmptyGuid, isGuid } from './shared/utils';
import { Provider as RTProvider } from '@fluentui/react-teams';
import { TeamsTheme } from '@fluentui/react-teams/lib/cjs/themes';


export interface AppProps {
	apiUrl: string;
	memberUrl: string;
	context: microsoftTeams.Context;
	reversion: number;
}
/**
 * 
 * @param props see AppProps
 * @returns inner App and Hub, wrapped in DndProvider and SignalR
 */
const InnerApp = (props: AppProps) => {
	const apiBasePath = useSelector((state: AppState) => state.system.apiBasePath);
	const apiKey = useSelector((state: AppState) => state.system.apiKey);
	const reversion = useSelector((state: AppState) => state.system.reversion);
	const token = useSelector((state: AppState) => state.system.token);
	const appClientId = useSelector((state: AppState) => state.system.appClientId);
	const error = useSelector((state: AppState) => state.system.error);
	const errorStatus = useSelector((state: AppState) => state.system.errorStatus);
	const location = useLocation();
	const history = useHistory();
	const [allRight, setAllRight] = useState(false);
	const [showReloadDialog, setShowReloadDialog] = useState(false);
	
	React.useEffect(() => {
		xhrEvents(
			() => SystemService.ensureToken(appClientId, true),
			() => {
				setShowReloadDialog(true);
				window.setTimeout(() => {
					window.location.reload();
				}, 4000);
			}
		);
	}, [appClientId]);

	useEffect(() => {
		if (allRight) {
			const storageKey = `votrSurveyId-${props.context.groupId}-${props.context.channelId}`;
			const parts = (location.pathname || '').split('/').filter((p) => !!p);
			if (parts.length === 0) {
				const surveyId = localStorage?.getItem(storageKey);
				if (isGuid(surveyId)) {
					history.push(`${surveyId}`);
				} else {
					SurveyService.loadSurvey().then((survey) => {
						history.push(`${survey.id}`);
					});
				}
			} else {
				const surveyId = parts[0];
				if (isGuid(surveyId)) {
					if (isEmptyGuid(surveyId)) {
						localStorage?.removeItem(storageKey);
						history.push(`/`);
					} else {
						SurveyService.loadSurvey(surveyId)
							.then((survey) => {
								localStorage?.setItem(storageKey, surveyId);
								MemberService.loadForSurvey(survey);
							})
							.catch((ex) => {
								localStorage?.removeItem(storageKey);
								history.push(`/`);
							});
					}
				}
			}
		}
	}, [allRight, location.pathname, history, props.context.groupId, props.context.channelId]);

	useEffect(() => {
		if (allRight) {
			SurveyService.loadSurveys();
			//update the aad token to avoid expired tokens
			window.setInterval(SystemService.updateToken, 1000 * 60 * 10);
		}
	}, [allRight]);

	if (!apiBasePath) {
		return <></>;
	}

	if (!isGuid(props.context.groupId)) {
		return (
			<MessageBar messageBarType={MessageBarType.blocked}>
				VOTR kann nicht in einem privaten Kanal genutzt werden!
			</MessageBar>
		);
	}

	SystemService.ensureConfig();

	if (reversion && reversion === props.reversion) {
		if (token) {
			SystemService.login();
		} else {
			SystemService.ensureToken(appClientId);
		}
	}

	if (error) {
		return (
			<MessageBar messageBarType={MessageBarType.error}>
				<b>{`Es ist ein Fehler aufgetreten: ${error}.`}</b>
				{errorStatus === 401 ? (
					<>
						<br />
						<span>
							VOTR wird neugeladen. Sollte der Fehler damit nicht behoben werden, starten Sie bitte Teams neu.
						</span>
					</>
				) : (
					''
				)}
			</MessageBar>
		);
	}

	if (reversion === null || reversion === undefined || !token || !apiKey) {
		if (reversion && reversion !== props.reversion) {
			return (
				<MessageBar messageBarType={MessageBarType.error}>App veraltet! Bitte aktualisieren Sie die App.</MessageBar>
			);
		}
		return (
			<>
				<ProgressIndicator label='Bitte warten' styles={{ root: { margin: '10px' } }} />
			</>
		);
	}

	if (!allRight) {
		setAllRight(true);
	}

	return (
		<>
			<DndProvider backend={HTML5Backend}>
				<div className={styles.app}>
					<LoadingOverlay
						excludeBusiness={[
							BusinessDataType.Question,
							BusinessDataType.Survey,
							BusinessDataType.Surveys,
							BusinessDataType.Members,
						]}
					>
						<div className={styles.appBody}>
							<Hub />
						</div>
					</LoadingOverlay>
				</div>
			</DndProvider>
			<Dialog
				hidden={!showReloadDialog}
				onDismiss={() => {}}
				dialogContentProps={{
					showCloseButton: false,
					type: DialogType.normal,
					title: 'Neuladen',
					subText: 'Es gibt ein Problem mit dem System. Zur Behebung wird VOTR neugeladen.',
				}}
				modalProps={{ isBlocking: true, styles: { main: { maxWidth: '450px !important' } } }}
			/>
		</>
	);
};

const App = (props: AppProps) => {
	useEffect(() => {
		store.dispatch(config(props.apiUrl, props.memberUrl, props.context));
	}, [props.apiUrl, props.memberUrl, props.context]);

	return (
		<RTProvider themeName={TeamsTheme.Default} lang='en-US'>
			<Provider store={store}>
				<SignalR url={props.apiUrl} userId={props.context.userObjectId}>
					<HashRouter basename='voting' hashType={'noslash'}>
						<InnerApp {...props} />
					</HashRouter>
				</SignalR>
				<Messages />
			</Provider>
		</RTProvider>
	);
};

loadTheme({
	palette: {
		themePrimary: '#0f73ba',
		themeLighterAlt: '#f3f8fc',
		themeLighter: '#d0e5f4',
		themeLight: '#aacfea',
		themeTertiary: '#60a5d6',
		themeSecondary: '#2581c2',
		themeDarkAlt: '#0d67a8',
		themeDark: '#0b578d',
		themeDarker: '#084068',
		neutralLighterAlt: '#e9eff8',
		neutralLighter: '#e5ebf4',
		neutralLight: '#dce2ea',
		neutralQuaternaryAlt: '#cdd2da',
		neutralQuaternary: '#c4c9d0',
		neutralTertiaryAlt: '#bcc1c8',
		neutralTertiary: '#92b2d1',
		neutralSecondary: '#4172a3',
		neutralPrimaryAlt: '#0e4378',
		neutralPrimary: '#003366',
		neutralDark: '#00274e',
		black: '#001d39',
		white: '#ffffff',
	},
});

export default App;
