import styles from './ToggleTextField.module.scss';
import React, { useState, useEffect } from 'react';
import { IconButton, TextField } from 'office-ui-fabric-react';


export interface Props {
	className?: string;
	maxLength?: number;
	value: string;
	readOnly?: boolean;
	required?: boolean;
	onSave: (value: string) => void;
}

export const ToggleTextField = (props: Props) => {
	const [value, setValue] = useState(props.value);
	const [isEditMode, setIsEditMode] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	const save = (val: string) => {
		if (props.required && !val) {
			setError('Bitte geben Sie einen Namen ein.');
			return;
		}
		setError('');
		props.onSave(val);
		setIsEditMode(false);
	};

	return (
		<div className={[styles.toggleField, props.className, isEditMode ? styles.editMode : ''].join(' ')}>
			{isEditMode ? (
				<TextField
					defaultValue={value}
					autoFocus={true}
					onBlur={(evt) => {
						setValue(evt.target.value);
						save(evt.target.value);
					}}
					errorMessage={error}
					maxLength={props.maxLength}
					onKeyUp={(evt) => {
						setError('');
						if (evt.key === 'Escape') {
							//escape
							setValue(props.value);
							setIsEditMode(false);
						} else if (evt.key === 'Enter') {
							//enter
							const v = (evt.target as HTMLInputElement).value;
							setValue(v);
							save(v);
						}
					}}
				/>
			) : (
				<span>{value}</span>
			)}
			{isEditMode ? (
				<IconButton
					iconProps={{
						iconName: 'StatusCircleCheckmark',
					}}
					onClick={() => {
						save(value);
					}}
				/>
			) : (
				<>
					{!props.readOnly ? <IconButton iconProps={{ iconName: 'Edit' }} onClick={() => setIsEditMode(true)} /> : ''}
				</>
			)}
		</div>
	);
};
