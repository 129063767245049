import styles from './LogoUploader.module.scss';
import React, { useEffect, useState } from 'react';
import { IconWithCallout } from '../atoms/IconWithCallout';
import { CommandButton } from 'office-ui-fabric-react';
import { DesignService } from '../../services/DesignService';
import ReactFileReader from 'react-file-reader';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { defaultLogo } from '../../images/defaultLogo';
import Compress from 'react-image-file-resizer';
import { IDesign } from '../../store/business/interfaces';
import { ConfirmationDialog } from '../atoms/ConfirmationDialog';

export interface LogoUploaderProps {
	readOnly?: boolean;
}
/**
 * - a FileReader to pick a Logo for the current Survey
 * - component state-handling with React useEffect & useState
 * @param props
 * @returns FileReader + Logo-Preview, Upload-Button, Delete-Button
 */
export const LogoUploader = (
	props: LogoUploaderProps,
	context: any,
	designService: DesignService = new DesignService()
) => {
	const design = useSelector((state: AppState) => state.business.design);
	const [tempDesign, setTempDesign] = useState<IDesign>(null);
	const [errorMessage, setErrorMessage] = useState<string>(null);
	const [successMessage, setSuccessMessage] = useState<string>(null);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const survey = useSelector((state: AppState) => state.business.survey);

	/**
	 * triggered onLoad and with change of Survey-Id,
	 * @event ensureDesign if existing, loads and initial Logo and sets the Design
	 */
	useEffect(() => {
		(async () => {
			await designService.ensureDesign(survey);
			setErrorMessage(null);
		})();
	}, [survey.id]);
	/**
	 * turns img-file from ReactFileReader into a cusomt-set size and quality
	 * turns file into base64
	 * state-handling: setTempDesign
	 * @param file Object with base64 string and FileList
	 * @param size custom set img size
	 */
	const resizeFile = (file: any, size: any) => {
		Compress.imageFileResizer(
			file.fileList[0],
			size,
			size,
			'PNG',
			100,
			0,
			(content: any) => {
				let logo = content.toString();
				setTempDesign({ ...design, logo });
			},
			'base64'
		);
	};
	/**
	 *
	 * @param file Object containing base64 string and FileList
	 * checks file for correct file-type: gif, png, jpeg, bmp
	 * catches and communicates SUccess/Errors to User, if file un/suitable
	 */
	const handleFiles = (file: { base64: string[]; fileList: FileList }) => {
		try {
			if (
				/^data:image\/(?:gif|png|jpeg|bmp)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/g.test(
					file.base64.toString()
				)
			) {
				resizeFile(file, 500);
				setSuccessMessage(
					'Das Logo entspricht den Vorgaben, mit einem Klick auf "Hochladen" können Sie es für diese Sitzung speichern.'
				);
				setErrorMessage(null);
			} else {
				setSuccessMessage(null);
				setErrorMessage(
					'Bitte wählen Sie eine Bild-Datei aus. Akzeptierte Formate sind .jpg, .jpeg, .png, .bmp und .gif.'
				);
			}
		} catch (ex) {
			setErrorMessage('Die ausgewählte Datei ist zu groß, bitte wählen Sie eine andere Datei.');
			setSuccessMessage(null);
		}
	};
	/**
	 * @event postDesign
	 * triggers Post-Request-Service
	 * state-handling component: sets Design, Error, Success
	 */
	const onUploadClick = async () => {
		if (tempDesign?.logo !== defaultLogo) {
			try {
				await designService.postDesign(tempDesign, survey);
				setTempDesign(null);
				setErrorMessage(null);
				setSuccessMessage('Das Logo wurde erfolgreich hochgeladen.');
			} catch (ex) {
				setErrorMessage('Es ist ein Fehler aufgetreten! Bitte versuchen Sie es erneut mit einem anderen Logo.');
				setSuccessMessage(null);
			}
		}
	};
	/**
	 * @event deleteDesign
	 * triggers Delete-Request-Service
	 * state-handling component: sets Design, Error, Success
	 */
	const onDeleteClick = async () => {
		await designService.deleteDesign(survey);
		setTempDesign(null);
		setErrorMessage(null);
		setSuccessMessage('Das Logo wurde erfolgreich gelöscht.');
	};

	return (
		<>
			<div className={styles.header}>
				<h4>Ihr Logo</h4>
				{!props.readOnly && (
					<IconWithCallout
						iconName='Info'
						renderCalloutContent={() => {
							return (
								<span>
									Hier können Sie ein Logo für diese Sitzung hochladen. Akzeptierte Formate sind .png, .jpg, .jpeg, .bmp und
									.gif.
								</span>
							);
						}}
						iconContainerClassName='votrInfoIcon'
					/>
				)}
			</div>
			<div className={styles.uploadContainer}>
				<div className={styles.logoContainer}>
					<ReactFileReader
						accept='image/*'
						fileTypes={'image/*'}
						base64={true}
						multipleFiles={true}
						handleFiles={handleFiles}
						className={styles.fileReader}
					>
						<div className={styles.imgContainer}>
							{design?.logo && !tempDesign?.logo && (
								<img className={styles.fileUploader} alt='Logo Vorschau' src={design?.logo} />
							)}
							{tempDesign?.logo && <img className={styles.fileUploader} alt='Logo Vorschau' src={tempDesign?.logo} />}
							{!design?.logo && !tempDesign?.logo && <label>Bild auswählen</label>}
						</div>
					</ReactFileReader>
				</div>
				<div className={styles.linkContainer}>
					<CommandButton
						onClick={onUploadClick}
						disabled={!tempDesign}
						iconProps={{ iconName: 'Upload' }}
						className={styles.button}
						text='Hochladen'
					/>
					<CommandButton
						onClick={() => setShowDeleteConfirmation(true)}
						disabled={!design}
						iconProps={{ iconName: 'Delete' }}
						className={styles.button}
						text='Löschen'
					/>
					<ConfirmationDialog
						hidden={!showDeleteConfirmation}
						title={'Logo löschen'}
						message={`Wollen Sie das Logo wirklich löschen?`}
						onConfirm={() => {
							setShowDeleteConfirmation(false);
							onDeleteClick();
						}}
						onDismiss={() => {
							setShowDeleteConfirmation(false);
						}}
					/>
				</div>
			</div>
			<div className={[styles.uploadError, errorMessage ? styles.showError : ''].join(' ')}>{errorMessage}</div>
			<div className={[styles.uploadSuccess, successMessage ? styles.showSuccess : ''].join(' ')}>{successMessage}</div>
		</>
	);
};
