import styles from './EntityList.module.scss';

import React, { useState, useCallback, ReactNode, useEffect, useRef } from 'react';
import { CommandButton } from 'office-ui-fabric-react';
import { IEntity } from '../../store/business/interfaces';
import { EntityItem } from '../atoms/EntityItem';
import update from 'immutability-helper';
import { createGuid, getNextFocusableElement } from '../../shared/utils';
import throttle from 'lodash/throttle';
import { ConfirmationDialog } from '../atoms/ConfirmationDialog';

export interface Props<T extends IEntity> {
	entities: T[];
	eTag?: string;
	disabled?: boolean;
	allowMove?: boolean;
	renderHeaderItem?: () => ReactNode;
	renderItem?: (
		entity: T,
		index: number,
		entities: T[],
		hitEnter: (entity: T, target: HTMLInputElement) => void
	) => ReactNode;
	onChange: (entities: T[]) => void;
	onDelete?: (entity: T) => void;
	deleteTitle?: string;
	deleteMessage?: string;
	deleteEmptyMessage?: string;
	onRenderNewEntityContainer?: (newEntityButton:ReactNode) => ReactNode;
	textNewEntity: string;
	textNewEmptyEntity: string;
	onRenderFooter?: () => ReactNode;
	renderDeleteColumn?: boolean;
	className?: string;
	defaultEmptyEntity?: T;
	showNewEntryRow?: boolean;
	renderNewEntryBelow: boolean;
}

export const EntityList = <T extends IEntity>(props: Props<T>) => {
	const [entities, setEntities] = useState(props.entities.map((e) => ({ ...e } as T)));
	const refList = useRef<HTMLTableElement>();
	const [focusFirstEntity, setFocusFirstEntity] = useState(false);
	const [focusLastEntity, setFocusLastEntity] = useState(false);

	const [hideDeleteDialog, setHideDeleteDialog] = useState(true);
	const [entityToDelete, setEntityToDelete] = useState(null);

	useEffect(() => {
		setEntities(props.entities.map((e) => ({ ...e } as T)));
/*		if(!props.entities[0]?.title) {
			setFocusFirstEntity(true);
		}*/
	}, [props.entities, props.eTag]);

	const onChangeEntity = (entity?: T, title?: string): { index: number; length: number } => {
		let index = -1;
		const newEntities = [...entities];
		let length = newEntities.length;
		const firstEntity = newEntities[0];
		const lastEntity = newEntities[length - 1];
		if (!entity) {
			//don't add empty entity if last entity is empty
			if ((firstEntity && !firstEntity.title) || (lastEntity && !lastEntity.title)) {
				return { index, length };
			}
			entity = {} as T;
			if(props.defaultEmptyEntity) {
				entity = {...props.defaultEmptyEntity};
			}
			entity = {
				...entity, 
				id: createGuid(),
				title: props.textNewEmptyEntity ? props.textNewEmptyEntity + ' ' + (entities.length + 1) : '',
			} as T;

			if(props.showNewEntryRow) {
				length = newEntities.unshift(entity);
				index = 0;
			} else {
				length = newEntities.push(entity);
				index = length - 1;
			}
		} else {
			entity.title = title;
			//remove last entity if title is not set
			if (lastEntity && lastEntity.id === entity.id && !title) {
				newEntities.splice(--length, 1);
			} else {
				index = newEntities.findIndex((e) => e.id === entity.id);
			}
		}
		setEntities(newEntities);
		props.onChange(newEntities);
		return { index, length };
	};

	useEffect(() => {
		if (focusFirstEntity) {
			try {
				refList.current.querySelector(`tbody tr.${styles.listItem}:first-child`).querySelector('input').focus();
			} catch {}
			setFocusFirstEntity(false);
		}
	}, [focusFirstEntity, entities]);

	useEffect(() => {
		if (focusLastEntity) {
			try {
				refList.current.querySelector(`tbody tr.${styles.listItem}.last`).querySelector('input').focus();
			} catch {}
			setFocusLastEntity(false);
		}
	}, [focusLastEntity, entities]);

	useEffect(() => {
		if(props.showNewEntryRow && props.showNewEntryRow === true) {
			onAddEntity();
		}
	}, [props.showNewEntryRow]);

	const onAddEntity = () => {
		onChangeEntity();
		
		if(props.showNewEntryRow) {
			setFocusFirstEntity(true);
		} else {
			setFocusLastEntity(true);
		}
	};

	const deleteEntity = (entity: T) => {
		const newEntities = entities.filter((e) => e.id !== entity.id);
		props.onDelete?.(entity);
		setEntities(newEntities);
		props.onChange(newEntities);
	};

	const moveEntity = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragEntity = entities[dragIndex];
			const newEntities = update(entities, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragEntity],
				],
			});
			setEntities(newEntities);
		},
		[entities]
	);

	const hitEnter = useCallback(
		throttle(
			(entity: T, target: HTMLInputElement) => {
				const index = entities.findIndex((e) => e.id === entity.id);
				const length = entities.length;
				const next = getNextFocusableElement(target, ['input']);
				if (next) {
					next.focus();
				} else if (index === length - 1) {
					//enter on last entity, creates new entity
					onAddEntity();
				}
			},
			100,
			{ leading: false, trailing: true }
		),
		[entities]
	);

	const addNewEntityButton = (
		<CommandButton
			text={props.textNewEntity}
			onClick={onAddEntity}
			onFocus={onAddEntity}
			disabled={props.disabled}
		/>
	);

	return (
		<>
			<ConfirmationDialog
				hidden={hideDeleteDialog}
				title={props.deleteTitle}
				message={entityToDelete?.title ? props.deleteMessage?.replace('{0}', entityToDelete?.title) : props.deleteEmptyMessage}
				onConfirm={()=>{
					setHideDeleteDialog(true);
					deleteEntity(entityToDelete);
					setEntityToDelete(null);
				}}
				onDismiss={()=>{
					setHideDeleteDialog(true);
				}}
			/>

			<table className={[styles.entityList, props.className].join(' ')} ref={refList}>
				{props.renderHeaderItem && (
					<thead>
						<tr className={styles.listHeader}>{props.renderHeaderItem()}</tr>
					</thead>
				)}
				<tbody>
					{entities.map((e, i, all) => {
						return (
							<EntityItem
								disabled={props.disabled}
								key={e.id}
								id={e.id}
								index={i}
								onDrop={props.allowMove && !props.disabled ? () => props.onChange(entities) : undefined}
								onMove={props.allowMove && !props.disabled ? moveEntity : undefined}
								className={[styles.listItem, (entities&&i===entities.length-1)?'last':''].join(' ')}
							>
								{props.renderItem && props.renderItem(e, i, all, hitEnter)}
								{!props.disabled && props.renderDeleteColumn && (
									<td className={styles.entityListDeleteIconContainer}>
										<CommandButton
											className={[styles.entityListDeleteIcon, 'noGrow'].join(' ')}
											iconProps={{ iconName: 'Delete' }}
											title='Löschen'
											onClick={() => {
												if(props.deleteTitle){
													setEntityToDelete(e);
													setHideDeleteDialog(false);
												}
												else {
													deleteEntity(e);													
												}
											}}
										/>
									</td>
								)}
							</EntityItem>
						);
					})}
					{(!props.disabled && props.renderNewEntryBelow) && (
						<>
						{props.onRenderNewEntityContainer && props.onRenderNewEntityContainer(addNewEntityButton)}
						{!props.onRenderNewEntityContainer && (
							<tr>
								<td>
									<div className={styles.newListItem} key={`new`}>
										{addNewEntityButton}
									</div>
								</td>
							</tr>
						)}
						</>
					)}
					{props.onRenderFooter && props.onRenderFooter()}
				</tbody>
			</table>
		</>
	);
};
