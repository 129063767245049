import React from 'react';
import {
	CommandButton,
	PrimaryButton,
	IContextualMenuProps,
	IContextualMenuItem,
	ContextualMenuItemType,
	Icon,
} from 'office-ui-fabric-react';
import { ISurvey, IMember, ISurveyBase, IQuestion } from '../../store/business/interfaces';
import { ToggleTextField } from '../atoms/ToggleTextField';
import styles from './SurveyControl.module.scss';
import { ToggleDatePicker } from '../atoms/ToggleDatePicker';
import format from 'date-fns/format';

export interface Props {
	surveys: ISurveyBase[];
	survey: ISurvey;
	questions: IQuestion[];
	members?: IMember[];
	onCreateQuestion: () => void;
	onManageMembers: () => void;
	onExportSurvey: () => void;
	onShareSurvey: () => void;
	onSelectSurvey: (survey: ISurveyBase) => void;
	onNewSurvey: () => void;
	onUpdateSurvey: (survey: ISurvey) => void;
	onArchiveSurvey: () => void;
	onDeleteSurvey: () => void;
	onEditSurvey: () => void;
}

export const SurveyControl = (props: Props) => {
	const surveyMenuProps: IContextualMenuProps = {
		items: ([] as IContextualMenuItem[]).concat(
			props.surveys
				?.filter((s) => s.status === 'live' || s.status === 'archived')
				.map((s) => {
					const dateString = s.date ? ' (' + format(s.date, 'dd.MM.yyyy') + ')' : '';
					return {
						id: s.id,
						text: `${s.title}${dateString}`,
						status: s.status,
						survey: s,
						sort: `${s.status === 'live' ? '5-' : '9-'}${
							Number.MAX_SAFE_INTEGER - (s.date ? s.date : s.created)?.getTime()
						}${s.title}`,
					};
				})
				.concat({ sort: '7-new', id: 'new', text: 'Neue Sitzung', status: 'unknown', survey: null })
				.sort((a, b) => {
					return a.sort.localeCompare(b.sort);
				})
				.reduce((agg, s, i, all) => {
					if (s.status === 'archived' && ((i > 0 && all[i - 1].status !== 'archived') || i === 0)) {
						const header = {
							key: s.status,
							text: 'Archiviert',
							itemType: ContextualMenuItemType.Header,
							className: styles.archive,
						} as IContextualMenuItem;
						agg.push(header);
					}
					const opt = {
						key: s.id,
						text: s.text,
						checked: s.id === props.survey.id,
						canCheck: true,
						onRenderContent: (p, dr) => {
							return (
								<>
									{dr.renderItemIcon(p)}
									{dr.renderItemName(p)}
									{dr.renderCheckMarkIcon(p)}
								</>
							);
						},
						iconProps: {
							iconName: s.status === 'archived' ? 'Archive' : '',
						},
						onClick: () => {
							props.onSelectSurvey(s.survey);
						},
					} as IContextualMenuItem;
					if (s.id === 'new') {
						agg.push({
							itemType: ContextualMenuItemType.Divider,
						} as IContextualMenuItem);

						opt.iconProps = { iconName: 'Add' };
						opt.onClick = props.onNewSurvey;
					}
					agg.push(opt);
					return agg;
				}, [])
		),
	};

	return (
		<div className={styles.surveyContainer}>
			<h1 className={styles.iconColorBlack}>
				{props.survey.status === 'archived' ? <Icon iconName={'Archive'} title='Diese Sitzung ist archiviert.' /> : ''}
				<ToggleTextField
					key={`${props.survey.id}-title`}
					className={styles.editTitle}
					maxLength={40}
					required={true}
					value={props.survey.title}
					readOnly={props.survey.readOnly}
					onSave={(title) => {
						props.onUpdateSurvey({...props.survey, title });
					}}
				/>
			</h1>

			<div className={styles.metadataContainer}>
				<CommandButton
					className={styles.sessionIdContainer}
					onClick={() => {
						props.onShareSurvey();
					}}
					menuProps={surveyMenuProps}
				>
					<b>Sitzungs-ID:&nbsp;</b>
					{props.survey.code}
				</CommandButton>
				<div className={styles.membersContainer}>
					<CommandButton
						iconProps={{ iconName: 'Group' }}
						text={props.members.length + ' Teilnehmer'}
						onClick={() => {
							props.onManageMembers();
						}}
					/>
				</div>
				<div className={styles.iconColorBlack}>
					<ToggleDatePicker
						key={`${props.survey.id}-date`}
						value={props.survey.date}
						readOnly={props.survey.readOnly}
						onSave={(date) => {
							props.onUpdateSurvey({ ...props.survey, date });
						}}
					/>
				</div>
				<div className={styles.moreImageButton}>
					<CommandButton
						iconProps={{ iconName: 'More' }}
						menuIconProps={{ iconName: '' }}
						menuProps={{
							items: [
								{
									key: 'editSurvey',
									iconProps: { iconName: 'Settings'},
									disabled: props.survey.readOnly,
									text: 'Sitzung verwalten',
									onClick: () => props.onEditSurvey(),
								},
								{
									key: 'shareSurvey',
									iconProps: { iconName: 'SplitObject', style: { transform: 'rotate(-90deg)' } },
									disabled: props.survey.readOnly,
									text: 'Sitzung teilen',
									onClick: () => props.onShareSurvey(),
								},
								{
									key: 'manageMembers',
									iconProps: { iconName: 'EditContact' },
									text: 'Teilnehmer',
									onClick: () => props.onManageMembers(),
								},
								{
									key: 'exportSurvey',
									iconProps: { iconName: 'Download' },
									text: 'Abstimmungsprotokoll exportieren',
									onClick: () => props.onExportSurvey(),
									disabled: props.questions?.some(q=>q.status === 'started'),
								},
								{
									key: 'divider1',
									itemType: ContextualMenuItemType.Divider
								},
								{
									key: 'archiveSurvey',
									iconProps: { iconName: 'Archive' },
									text: 'Sitzung archivieren',
									onClick: () => props.onArchiveSurvey(),
									disabled: props.questions?.some(q=>q.status === 'started') || props.survey.readOnly,
								},
								{
									key: 'deleteSurvey',
									iconProps: { iconName: 'Delete' },
									text: 'Sitzung löschen',
									onClick: () => props.onDeleteSurvey(),
									disabled: props.questions?.some(q=>q.status === 'started'),
								},
							],
						}}
					/>
				</div>
			</div>
			
			<div className={styles.listHeaderContainer}>
				<h2>Abstimmungen</h2>
				<PrimaryButton
					iconProps={{ iconName: 'Add' }}
					text='Neue Abstimmung'
					disabled={props.survey.readOnly}
					onClick={() => {
						props.onCreateQuestion();
					}}
				/>
			</div>
		</div>
	);
};
