import round from "lodash/round";
import floor from "lodash/floor";
import { ITextFieldProps, TextField } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";

export interface Props extends Omit<ITextFieldProps, 'defaultValue'|'onChange'|'type'|'min'|'step'> {
	precision: number;
	defaultValue: number;
	onChange: (event: React.FormEvent<HTMLInputElement|HTMLTextAreaElement>, newValue?:number) => void;
}

export const NumberTextField = (props: Props) => {
	const [textValue, setTextValue] = useState(`${round(props.defaultValue, props.precision)}`);
	const [step, setStep] = useState(Math.pow(10, -1*props.precision));

	useEffect(() => {
		setTextValue(`${round(parseFloat(textValue), props.precision)}`);
		setStep(Math.pow(10, -1*props.precision));
	}, [props.precision, textValue]);

	return (
		<TextField
			{...props}
			defaultValue={`${round(props.defaultValue, props.precision)}`}
			value={textValue}
			type='number'
			min={step}
			step={step}
			onChange={(e,v)=>{
				const newValue = floor(parseFloat(v), props.precision) || 0;
				const oldValue = floor(parseFloat(textValue), props.precision) || 0;
				if(newValue !== oldValue) {
					setTextValue(`${newValue}`);
					props.onChange(e, newValue);
				}
			}}
		/>
	);
};